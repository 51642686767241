<template>
  <ion-card
    :kind="isPlanManaged || isImprovedLifeChoices ? 'clickable' : null"
    :tabindex="isPlanManaged || isImprovedLifeChoices ? 0 : -1"
    :class="
      `plan-support-category-${planSupportCategory.support_category?.support_category_number} ${
        isPlanManaged || isImprovedLifeChoices ? 'clickable' : ''
      }`
    "
  >
    <div class="card-wrap">
      <p class="title">{{ planSupportCategory.support_category?.support_category_name }}</p>
      <progress-bar :percentage-left="percentageBudgetLeft" :proportional-width="progressBarWidth" />
      <p class="sub-title">
        {{ percentageBudgetLeft }}% SPENT -
        {{ $filters.currency(planSupportCategory.budget_allocated - planSupportCategory.budget_spent) }} LEFT
      </p>
      <div v-if="planSupportCategory.managed === 'plan' || isImprovedLifeChoices" class="managed-logo-container">
        <p class="total">{{ $filters.currency(planSupportCategory.budget_allocated) }} TOTAL</p>
        <ion-icon :src="`${$brand_svg_src}/managed.svg`" class="managed-logo self-managed"></ion-icon>
        <span class="managed-text" v-if="$brand === 'ndsp'">Plan Managed</span>
      </div>
      <div v-if="planSupportCategory.managed === 'agency' && !isImprovedLifeChoices" class="managed-logo-container">
        <p class="total">{{ $filters.currency(planSupportCategory.budget_allocated) }} TOTAL</p>
        <ion-icon :src="`${$brand_svg_src}/ndis.svg`" class="managed-logo"></ion-icon>
        <span v-if="$brand === 'ndsp'" class="managed-text">Agency managed</span>
      </div>
      <div v-if="planSupportCategory.managed === 'self'" class="managed-logo-container">
        <p class="total">{{ $filters.currency(planSupportCategory.budget_allocated) }} TOTAL</p>
        <ion-icon :src="`${$brand_svg_src}/self.svg`" class="managed-logo self-logo">
          <span v-if="$brand === 'ndsp'" class="managed-text">Self managed</span>
        </ion-icon>
      </div>
    </div>
  </ion-card>
</template>
<script>
import { defineComponent } from "vue";
import currency from "@/filters/currency";
import ProgressBar from "@/components/plans/ProgressBar.vue";
import Ndis from "@/assets/icons/ndis-logo--small.svg";
import Self from "@/assets/icons/selfmanaged-logo--small.svg";
import MpmIcon from "@/assets/icons/mpm-logo--small.svg";
import { IonIcon, IonCard } from "@ionic/vue";

const CB_CHOICE_CONTROL_ID = 14;

export default defineComponent({
  props: {
    planSupportCategory: {
      type: Object,
      required: true
    }
  },
  components: {
    Ndis,
    ProgressBar,
    Self,
    MpmIcon,
    IonIcon,
    IonCard
  },
  filters: {
    currency
  },
  computed: {
    progressBarWidth() {
      const totalPlanBudget = this.$store.getters.currentPlan.budget_allocated;
      return Math.abs((this.planSupportCategory.budget_allocated / totalPlanBudget) * 100);
    },
    isPlanManaged() {
      return this.planSupportCategory.managed === "plan";
    },
    isImprovedLifeChoices() {
      return this.planSupportCategory.support_category?.support_category_number === CB_CHOICE_CONTROL_ID;
    },
    percentageBudgetLeft() {
      // pre-empt divide by zero error
      if (this.planSupportCategory.budget_spent === 0) {
        return 0;
      }
      return ((this.planSupportCategory.budget_spent / this.planSupportCategory.budget_allocated) * 100).toFixed(0);
    }
  }
});
</script>
<style lang="scss" scoped>
ion-card {
  margin: 0 10px 16px 10px;
  cursor: pointer;
  &.clickable:focus-visible {
    outline: 3px solid $brand--focus-outline;
    outline-offset: 2px;
  }
}
.card-wrap {
  padding: 10px 10px 10px 15px;
  .title {
    max-width: 520px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $brand--font-color-primary;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 1.5;
  }
  .sub-title {
    color: $brand--font-color-primary;
    font-size: 0.82rem;
    line-height: 1;
    letter-spacing: 0.085em;
    font-weight: 600;
    margin: 0;
  }

  .managed-logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    .total {
      color: $brand--plan-support-category-total-color;
      width: 100%;
      font-size: 0.75rem;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .managed-text {
    letter-spacing: 0.16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 10px;
    color: $brand--primary-color;
    white-space: nowrap;
  }

  .managed-logo-text {
    height: 20px;
    width: 105px;
  }

  @if $brand == "ndsp" {
    ion-icon.managed-logo {
      height: 1rem;
      opacity: 1;

      &.self-logo {
        height: 1.5rem;
      }
    }
  }

  @media only screen and (max-width: 42rem) {
    .bx--tile.selected {
      width: calc(100% - 40px) !important;
    }
    .bx--tile.selected::after {
      display: none;
    }
    .managed-logo-container {
      padding-top: 1rem;
    }
  }
}
.plan-support_collection {
  ion-card.plan-support-category {
    position: relative;

    &:focus-visible {
      outline: 3px solid $brand--focus-outline-button-secondary;
    }
  }
}

.collapsed {
  .bx--tile.plan-support-category {
    width: 100%;

    .bx--tile.selected {
      box-shadow: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 0;
      padding-right: 35px;
      width: calc(100% + 20px) !important;
      max-width: 525px;

      .managed-logo {
        right: 34px;
      }
    }

    .bx--tile.selected:after {
      content: "";
      position: absolute;
      right: -23px;
      top: 40%;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
  }
}

.collapsed {
  .bx--tile--clickable {
    color: unset;
  }

  .bx--tile.selected {
    box-shadow: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    padding-right: 35px;
    width: calc(100% + 20px) !important;
    max-width: 525px;

    .managed-logo {
      right: 28px;
    }

    &:focus:after {
      border-left-color: white !important;
    }
  }

  .bx--tile.selected:after {
    content: "";
    position: absolute;
    right: -24px;
    top: 40%;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
}
</style>
