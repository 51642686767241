const login = {
  success: true,
  code: 200,
  message: null,
  data: {
    token:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkVickFITm9iOEVWaGVWWnhQLVFKRCJ9.eyJodHRwczovL215LnBsYW4ubWFuYWdlci9tcG1faWQiOjYwNTUxLCJpc3MiOiJodHRwczovL21wbS10MnAuYXUuYXV0aDAuY29tLyIsImF1ZCI6ImJ1dVBxT0RNVTVBME1hcGlOVEYxUUx4NnhVS0VEenFPIiwiaWF0IjoxNjkwODUzNDMxLCJleHAiOjE2OTA4ODk0MzEsInN1YiI6InNtc3w2NDEyODUwYmE1ZjUwNGNmY2Y3N2MyMmMifQ.lcUKxwr3nd_PqM6tFv0Y8IvQ9_fZyrVcGcNYPnrZ8OE_EzASSRPkM5bs2hBeyJ54_MNoTegcQHOWAW6c2Zh__cspJomNRkQrE1Trrs26f2pJSnZz8zD8TkL6jTdyWVItRsqVF2xhgZcMzHajKpJ_UcDNOld8A72BZBrhm1rLcZbSNPYxrLiRDA2koMzFhQyobigkv78SSMScHZ86rHUyMo9hrtuiymptQJpmdfYR4baKbumZLRo5O7Mxj68MgCtyLgBwa6ga8_J9B6XVZM592C7J-ecezOdoo2zn9w7Xc0p7oAEMrluS9Asd7ALliUJrAbO9cq9sbE1Cjk7mJORm4Q",
    refresh_token: "zKf1KWFVhZdF0CoCxSHKlUsWDh1Ctg-Mi2lnFN44udCY6",
    options: null,
    user: {
      id: 60551,
      contact_id: 342638,
      contact_sfid: "0032N00000IvBBhQAN",
      username: "mj81hideki@gmail.com",
      login_finished: false,
      created_at: "2022-05-19 11:12:01",
      updated_at: "2023-01-19 11:39:24",
      deleted_at: "",
      options: {
        step: 1
      },
      last_login_at: "2023-01-19 11:39:24",
      api_token: null,
      enabled_login: true,
      azure_id: null,
      contact: {
        id: 95116,
        sfid: "0032N00000IvBBhQAN",
        type: "coordinator",
        first_name: "Yoshi",
        name: "Yoshi MPM Technlogy",
        last_name: "MPM Technlogy",
        account_name: "Yoshi MPM Technlogy",
        suburb: null,
        post_code: null,
        state: null,
        address: null,
        phone: null,
        mobile_phone: "0437812389",
        email: "mj81hideki@gmail.com",
        created_at: "2022-05-19 01:40:58",
        updated_at: "2023-06-16 05:53:24",
        full_name: "Yoshi MPM Technlogy"
      },
      profile: {
        id: 95116,
        sfid: "0032N00000IvBBhQAN",
        type: "coordinator",
        first_name: "Yoshi",
        name: "Yoshi MPM Technlogy",
        last_name: "MPM Technlogy",
        account_name: "Yoshi MPM Technlogy",
        suburb: null,
        post_code: null,
        state: null,
        address: null,
        phone: null,
        mobile_phone: "0437812389",
        email: "mj81hideki@gmail.com",
        created_at: "2022-05-19 01:40:58",
        updated_at: "2023-06-16 05:53:24",
        full_name: "Yoshi MPM Technlogy"
      },
      roles: []
    }
  }
};

const start = {
  success: true,
  code: 200,
  message: null,
  data: {
    masked_mobile_number: "04xx xxx x89"
  }
};

export { login, start };
