const maintenance = {
  migrating: true, // true if in maintenance mode, otherwise will be omitted or false
  success: true,
  code: 200,
  message: null,
  data: {} // Data fields TBD
};

const appStatus = {
  success: true,
  code: 200,
  message: null
};

export { maintenance, appStatus };
