import { createServer, Model } from "miragejs";
import { login, start } from "./mockups/response/auth";
import { appStatus } from "./mockups/response/maintenance";
import {
  clients,
  csat,
  me,
  contact,
  updateCsat,
  smsNotification,
  autoApprove,
  requestPhoneCall
} from "./mockups/response/users";
import { reports, generatedReport } from "./mockups/response/reports";
import {
  claim,
  claims,
  reimbursements,
  uploadedClaims,
  invoiceLineItems,
  attachment,
  submitClaim,
  claimsForApprovalsCount,
  claimsApprovals,
  approveClaim,
  claimSuggestions
} from "./mockups/response/claims";
import { providers, providersSuggestions, providerClaims } from "./mockups/response/providers";
import { plans, planSupports, planSupportItems, supportItems, planSupportPreferences } from "./mockups/response/plans";
import {
  supportTypes,
  supportType,
  pacePlanSupports,
  pacePlanSupportItems,
  pacePlanSupport,
  pacePlanSupportPreference
} from "./mockups/response/pace-plans";

export function makeServer({ environment = "development" } = {}) {
  const server = createServer({
    environment,

    models: {
      user: Model
    },

    routes() {
      this.urlPrefix = "https://svc-mpm-api-int.herokuapp.com";
      this.namespace = "api";
      const apiPrefix = "/client-portal/v1";
      const baseUrl = this.urlPrefix + "/" + this.namespace + apiPrefix;

      // Pass through API calls
      // this.pretender.get(`${baseUrl}/clients/:clientId/plans`, this.pretender.passthrough);
      this.pretender.get("*", this.pretender.passthrough);
      this.pretender.get("data:image/*", this.pretender.passthrough);
      this.pretender.get("/svg/*", this.pretender.passthrough);
      const commonRespData = {
        min_version: "7.1.0",
        max_version: "7.1.2"
      };

      // Mockup API calls
      this.post("/auth/passwordless/start", () => {
        return { ...start, ...commonRespData };
      });

      this.post("/auth/passwordless/login", () => {
        return { ...login, ...commonRespData };
      });

      this.get("/auth/me", () => {
        return { ...me, ...commonRespData };
      });

      this.put("/contact", () => {
        return { ...contact, ...commonRespData };
      });

      this.get(apiPrefix + "/status", () => {
        return { ...appStatus, ...commonRespData };
      });

      this.get(apiPrefix + "/clients", () => {
        return { ...clients, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/plans", () => {
        return { ...plans, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices/uploaded", () => {
        return { ...uploadedClaims, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices", (schema, request) => {
        const { filter___paid_to_type } = request.queryParams;
        if (filter___paid_to_type === "reimbursement") {
          return { ...reimbursements, ...commonRespData };
        } else {
          return { ...claims, ...commonRespData };
        }
      });

      this.post(apiPrefix + "/clients/:clientId/invoices", () => {
        return { ...submitClaim, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices/approvals", () => {
        return { ...claimsForApprovalsCount, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices/:claimId", () => {
        return { ...claim, ...commonRespData };
      });

      this.put(apiPrefix + "/clients/:clientId/invoices/:claimId", () => {
        return { ...approveClaim, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices", () => {
        return { ...claimsApprovals, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices/autosuggest", () => {
        return { ...claimSuggestions, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices/:invoiceId/invoice-line-items", () => {
        return { ...invoiceLineItems, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/invoices/:invoiceId/attachment", () => {
        return { ...attachment, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/plans/:planId/plan-supports", () => {
        return { ...planSupports, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/reports", () => {
        return { ...reports, ...commonRespData };
      });

      this.post(apiPrefix + "/clients/:clientId/reports/plans/:planId/generate", () => {
        return { ...generatedReport, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/plans/:planId/plan-supports/:planSupportId/plan-support-items", () => {
        return { ...planSupportItems, ...commonRespData };
      });

      this.get(apiPrefix + "/providers", () => {
        return { ...providers, ...commonRespData };
      });

      this.get(apiPrefix + "/providers/clients/:clientId/autosuggest", () => {
        return { ...providersSuggestions, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/plans/:planId/provider-invoices", () => {
        return { ...providerClaims, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/plans/:planId/plan-supports/:planSupportId/support-items", () => {
        return { ...supportItems, ...commonRespData };
      });

      this.post(
        apiPrefix + "/clients/:clientId/plans/:planId/plan-supports/:planSupportId/plan-support-preferences",
        () => {
          return { ...planSupportPreferences, ...commonRespData };
        }
      );

      this.get(apiPrefix + "/users/clients/:clientId/settings/csat", () => {
        return { ...csat, ...commonRespData };
      });

      this.put(apiPrefix + "/users/clients/:clientId/settings/sms-notification", () => {
        return { ...smsNotification, ...commonRespData };
      });

      this.put(apiPrefix + "/users/clients/:clientId/settings/auto-approve", () => {
        return { ...autoApprove, ...commonRespData };
      });

      this.post(apiPrefix + "/clients/:clientId/request-phone-call", () => {
        return { ...requestPhoneCall, ...commonRespData };
      });

      this.put(apiPrefix + "/users/settings/csat", () => {
        return { ...updateCsat, ...commonRespData };
      });

      // PACE Plan APIs
      this.get(apiPrefix + "/clients/:clientId/plans/:planId/support-types", () => {
        return { ...supportTypes, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/plans/:planId/support-types/:supportType", () => {
        return { ...supportType, ...commonRespData };
      });

      this.get(apiPrefix + "/clients/:clientId/plans/:planId/support-types/:supportType/plan-supports", () => {
        return { ...pacePlanSupports, ...commonRespData };
      });

      this.get(
        apiPrefix + "/clients/:clientId/plans/:planId/support-types/:supportType/plan-supports/:supportId",
        () => {
          return { ...pacePlanSupport, ...commonRespData };
        }
      );

      this.get(
        apiPrefix +
          "/clients/:clientId/plans/:planId/support-types/:supportType/plan-supports/:supportId/plan-support-preferences",
        () => {
          return { ...pacePlanSupportPreference, ...commonRespData };
        }
      );

      this.get(
        apiPrefix +
          "/clients/:clientId/plans/:planId/support-types/:supportType/plan-supports/:supportId/plan-support-items",
        () => {
          return { ...pacePlanSupportItems, ...commonRespData };
        }
      );
    }
  });

  return server;
}
