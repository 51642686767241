const supportTypes = {
  success: true,
  code: 200,
  data: {
    support_types: [
      {
        name: "Core",
        allocated: 3000.95,
        spent: 1000.51,
        remaining: 2000.44
      },
      {
        name: "Capital",
        allocated: 5000.95,
        spent: 1000.51,
        remaining: 4000.44
      },
      {
        name: "Capacity Building",
        allocated: 2000.95,
        spent: 1000.51,
        remaining: 1000.44
      }
    ]
  }
};

const supportType = {
  success: true,
  code: 200,
  data: {
    name: "Core",
    allocated: 3000.95,
    spent: 1000.51,
    remaining: 2000.44
  }
};

const pacePlanSupports = {
  data: [
    {
      budget_allocated: 47980.18,
      budget_left: 36243.66,
      budget_spent: 6736.52,
      budget_reserved: 5000,
      budget_unallocated: 36243.66,
      id: 1,
      managed: "plan",
      plan_sfid: "a1a2N00000276k13b",
      sfid: "a1Z2N000002v723yr",
      support_category_name: "Relationships",
      support_category: {
        code: "Relationships",
        id: 10,
        name: "Improved Relationships",
        sfid: "a0c2N000001369DQAQ",
        support_category_name: "Improved Relationships",
        support_category_number: 11,
        support_type_code: "ZCAP",
        support_type_name: "Capacity Building",
        pace_support_category_name: "Relationships",
        pace_support_category_code: "Relationships"
      },
      payees: [
        {
          sfid: "SFIF8148",
          name: "Test Company ltd",
          spent: 12345.12
        }
      ]
    },
    {
      budget_allocated: 47980.18,
      budget_left: -10000,
      budget_spent: 57980.18,
      budget_unallocated: 0,
      id: 2,
      managed: "plan",
      plan_sfid: "a1a2N00000276k13b",
      sfid: "a1Z2N000002v723yr",
      support_category_name: "Relationships",
      support_category: {
        code: "Relationships",
        id: 10,
        name: "Improved Relationships",
        sfid: "a0c2N000001369DQAQ",
        support_category_name: "Improved Relationships",
        support_category_number: 11,
        support_type_code: "ZCAP",
        support_type_name: "Capacity Building",
        pace_support_category_name: "Relationships",
        pace_support_category_code: "Relationships"
      },
      payees: [
        {
          sfid: "SFIF8148",
          name: "Test Company ltd",
          spent: 1234.12
        }
      ]
    },
    {
      budget_allocated: 47980.18,
      budget_left: 41243.66,
      budget_spent: 6736.52,
      budget_unallocated: 41243.66,
      id: 3,
      managed: "agency",
      plan_sfid: "a1a2N00000276k13b",
      sfid: "a1Z2N000002v723yr",
      support_category_name: "Relationships",
      support_category: {
        code: "Relationships",
        id: 10,
        name: "Improved Relationships",
        sfid: "a0c2N000001369DQAQ",
        support_category_name: "Improved Relationships",
        support_category_number: 11,
        support_type_code: "ZCAP",
        support_type_name: "Capacity Building",
        pace_support_category_name: "Relationships",
        pace_support_category_code: "Relationships"
      },
      payees: [
        {
          sfid: "SF1312u983y",
          name: "Myself",
          spent: 1234.67
        },
        {
          sfid: "SFIF8148",
          name: "Test Company ltd",
          spent: 12345.12
        }
      ]
    }
  ]
};

const pacePlanSupport = {
  data: {
    budget_allocated: 47980.18,
    budget_left: 41243.66,
    budget_spent: 6736.52,
    budget_unallocated: 41243.66,
    id: 1,
    managed: "agency",
    plan_sfid: "a1a2N00000276k13b",
    sfid: "a1Z2N000002v723yr",
    support_category_name: "Relationships",
    support_category: {
      code: "CB_RELATIONSHIPS",
      id: 10,
      name: "Improved Relationships",
      sfid: "a0c2N000001369DQAQ",
      support_category_name: "Improved Relationships",
      support_category_number: 11,
      support_type_code: "ZCAP",
      support_type_name: "Capacity Building",
      pace_support_category_name: "Relationships",
      pace_support_category_code: "Relationships"
    }
  }
};

const pacePlanSupportItems = {
  data: {
    plan_support_preferences: [
      {
        sfid: "SFIF8148",
        name: "Myself",
        budget_spent: 1234500.12,
        budget_allocated: 123400.45,
        plan_support_items: [
          {
            budget_spent: 3000.0,
            budget_allocated: 10000.0,
            budget_remaining: 7000.0,
            name: "Assistive",
            code: "120_202_20200_0",
            start_date: "2023-05-05",
            end_date: "2023-06-06"
          }
        ]
      },
      {
        sfid: "SFIF82348",
        name: "JB HIFI",
        budget_spent: 1234500.12,
        budget_allocated: 123400.45,
        plan_support_items: [
          {
            budget_spent: 3000.0,
            budget_allocated: 10000.0,
            budget_remaining: 7000.0,
            name: "Assistive",
            code: "120_202_20200_0",
            start_date: "2023-05-05",
            end_date: "2023-06-06"
          },
          {
            budget_spent: 3000.0,
            budget_allocated: 10000.0,
            budget_remaining: 7000.0,
            name: "Assistive",
            code: "120_202_20200_0",
            start_date: "2023-05-05",
            end_date: "2023-06-06"
          }
        ]
      }
    ],
    reimbursements: [
      {
        sfid: "SFIF8148",
        name: "Myself",
        budget_spent: 12345.12,
        plan_support_items: [
          {
            budget_spent: 1234.45,
            name: "Assistive",
            code: "120_202_20200_0"
          },
          {
            budget_spent: 1234.45,
            name: "Assistive",
            code: "120_202_20200_0"
          }
        ]
      }
    ],
    providers: [
      {
        sfid: "SFIF8148",
        name: "Test Company ltd",
        budget_spent: 4500.12,
        plan_support_items: [
          {
            budget_spent: 500.45,
            name: "Assistive",
            code: "120_202_20200_0"
          },
          {
            budget_spent: 500.45,
            name: "Assistive",
            code: "120_202_20200_0"
          }
        ]
      }
    ]
  }
};

const pacePlanSupportPreference = {
  data: {
    budget_allocated: 47980.18,
    budget_left: 40000.16,
    budget_spent: 7980.02
  }
};

export {
  supportTypes,
  supportType,
  pacePlanSupports,
  pacePlanSupportItems,
  pacePlanSupport,
  pacePlanSupportPreference
};
