<template>
  <div class="settings-toggle-row" >
    <h3 :id="ariaLabelId">{{ label }}</h3>
    <span class="together">
      <ion-toggle tabindex="0" :aria-labelledby="ariaLabelId" v-bind="propsAndAttrs" />
      <p v-if="switchLabel">{{ switchLabel }}</p>
    </span>
  </div>
</template>

<script>
import { IonToggle } from "@ionic/vue";

export default {
  name: "SettingsToggleRow",
  props: {
    label: String,
    switchLabel: String,
    ariaLabelId: String
  },
  components: {
    IonToggle
  },
  computed: {
    propsAndAttrs() {
      return { ...this.$props, ...this.$attrs };
    }
  }
};
</script>

<style lang="scss" scoped>
.settings-toggle-row {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .together {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0 10px;
    }
  }
  h3 {
    font-size: 18px;
  }
  * {
    flex: 0 1 auto;
  }
}
ion-toggle:focus,
ion-toggle:focus-visible {
  outline: 3px solid $brand--focus-outline;
  padding: 3px;
}
</style>
