<template>
  <div>
    <template v-if="isCoreSupportsNotEmpty">
      <PlanSupportCategoryGroup
        title="Core Supports"
        :plan-support-categories="coreSupports"
        tooltip-content="Funding to support all your assistance with day-to-day tasks and where all your carer, support worker, cleaning and other regular expenses will be paid from"
      />
    </template>
    <template v-if="isCapitalSupportsNotEmpty">
      <PlanSupportCategoryGroup
        title="Capital Supports"
        :plan-support-categories="capitalSupports"
        tooltip-content="Funds to pay for products to reduce the impact of your disability and usually cover specific items which were discussed in your planning meeting and have been approved in your plan by the NDIA."
      />
    </template>
    <template v-if="isCapacitySupportsNotEmpty">
      <PlanSupportCategoryGroup
        title="Capacity Building Supports"
        :plan-support-categories="capacitySupports"
        tooltip-content="Funding specifically allocated to assist you to build your skills and achieve your goals"
      />
    </template>
  </div>
</template>
<script type="ts">
import {defineComponent} from "vue";
import PlanSupportCategoryGroup from "@/components/plans/PlanSupportCategoryGroup.vue";
import groupPlanSupportCategories from "@/helpers/plans/groupPlanSupportCategories";

export default defineComponent({
    props: {
        planSupportCategories: {
            type: Array,
            required: true
        }
    },
    data() {
      return {
          coreSupports:[],
          capitalSupports: [],
          capacitySupports: []
      }
    },
    watch: {
      planSupportCategories() {
        this.groupPlanSupports();
      }
    },
    components: { PlanSupportCategoryGroup },
    computed: {
      isCoreSupportsNotEmpty() {
        return this.coreSupports.length > 0
      },
      isCapitalSupportsNotEmpty() {
        return this.capitalSupports.length > 0
      },
      isCapacitySupportsNotEmpty() {
        return this.capacitySupports.length > 0
      }
    },
    methods: {
      groupPlanSupports() {
        const planSupportCategoriesGroups = groupPlanSupportCategories(this.planSupportCategories);
        this.coreSupports = planSupportCategoriesGroups.coreSupports;
        this.capitalSupports = planSupportCategoriesGroups.capitalSupports;
        this.capacitySupports = planSupportCategoriesGroups.capacitySupports;
      }
    },
    mounted() {
        this.groupPlanSupports();
    }
})
</script>
