const claims = {
  success: true,
  code: 200,
  message: null,
  data: [
    {
      sfid: "a1Z2N000003fK9EUAU",
      client_approval_status: "Approved",
      created_at: "2023-04-05 02:20:22",
      total: "20",
      status: "Awaiting Client Approval",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "TEST288",
      service_date: "2023-04-04",
      service_date_to: "2023-04-04",
      provider: {
        sfid: "0015K000002grDyQAI",
        account_name: "Test Provider"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000FLR0nQAH",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "20"
    },
    {
      sfid: "a1Z2N000003eG0qUAE",
      client_approval_status: "Rejected",
      created_at: "2023-02-27 00:07:43",
      total: "1",
      status: "Awaiting Client Approval",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "2",
      service_date: "2023-04-03",
      service_date_to: "2023-04-03",
      provider: {
        sfid: "0015K000002gWtAQAU",
        account_name: "The Garden Ninjas (Chris Rostron)"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5005K000006qrGdQAI",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "1"
    },
    {
      sfid: "a1Z2N000003fIf5UAE",
      client_approval_status: null,
      created_at: "2023-04-03 04:07:01",
      total: "62.17",
      status: "Awaiting Client Approval",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "INV-02",
      service_date: "2023-03-22",
      service_date_to: "2023-03-22",
      provider: {
        sfid: "0015K000002gs0AQAQ",
        account_name: "Novita (The Spastic Centres Of SA Inc)"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link:
        "https://svc-claims-automation-staging.s3.ap-southeast-2.amazonaws.com/rapidMATION/ABBYY/Invoices/06792647_34b053eb401b7d9a82555dc9d355fc5ee438472d.pdf?AWSAccessKeyId=AKIA32R76EOMRPXWU7UV&Expires=1838347525&Signature=e%2B3e1WUa5aBQrPIfw%2BHUxTeHH4w%3D",
      case: {
        sfid: "5002N00000FLErSQAX",
        origin: "Email"
      },
      protect_client_data: false,
      amount_due: "62.17"
    },
    {
      sfid: "a1Z2N000003fIeRUAU",
      client_approval_status: "Rejected",
      created_at: "2023-04-03 03:33:00",
      total: "62.17",
      status: "Declined By Client",
      sf_invoice_status: "Cancelled",
      reference_number: "INV-01",
      service_date: "2023-03-21",
      service_date_to: "2023-03-21",
      provider: {
        sfid: "0015K000002gs0AQAQ",
        account_name: "Novita (The Spastic Centres Of SA Inc)"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link:
        "https://svc-claims-automation-staging.s3.ap-southeast-2.amazonaws.com/rapidMATION/ABBYY/Invoices/06792646_c7b7916bda3685fc15ae5aba9abbfe3f8fc2bf82.pdf?AWSAccessKeyId=AKIA32R76EOMRPXWU7UV&Expires=1838345503&Signature=RGKVWRC1wbQtFr2BcfT56QXY7QE%3D",
      case: {
        sfid: "5002N00000FLEK5QAP",
        origin: "Email"
      },
      protect_client_data: false,
      amount_due: "62.17"
    },
    {
      sfid: "a1Z2N000003eJJkUAM",
      client_approval_status: "Approved",
      created_at: "2023-03-09 04:55:28",
      total: "10",
      status: "Awaiting Payment",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "testing1023",
      service_date: "2023-03-01",
      service_date_to: "2023-03-02",
      provider: {
        sfid: null,
        account_name: "Myself"
      },
      paid_to_type: "reimbursement",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000FIEOcQAP",
        origin: "Auto Generated"
      },
      protect_client_data: false,
      amount_due: "10"
    },
    {
      sfid: "a1Z2N000003eHZzUAM",
      client_approval_status: null,
      created_at: "2023-03-02 04:51:22",
      total: "50",
      status: "Awaiting Payment",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "test",
      service_date: "2023-03-01",
      service_date_to: "2023-03-01",
      provider: {
        sfid: null,
        account_name: "Myself"
      },
      paid_to_type: "reimbursement",
      date_paid: null,
      document_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/BdevQv2EjAY9DEMWD26aETSfxERN2Fca9l6BYvCN.png",
      case: {
        sfid: "5002N00000FHmLSQA1",
        origin: "Client Portal"
      },
      protect_client_data: false,
      amount_due: "50"
    },
    {
      sfid: "a1Z2N000003eFIdUAM",
      client_approval_status: "Rejected",
      created_at: "2023-02-23 09:31:39",
      total: "2",
      status: "Declined By Client",
      sf_invoice_status: "Cancelled",
      reference_number: "CWERX_Test_Record57",
      service_date: "2023-02-23",
      service_date_to: "2023-02-23",
      provider: {
        sfid: "0015K000002gWreQAE",
        account_name: "CENTRAL OSTEOPATHY PTY LTD (Admin Contact)"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5005K000006qrGdQAI",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "2"
    },
    {
      sfid: "a1Z2N000003eFIYUA2",
      client_approval_status: "Rejected",
      created_at: "2023-02-23 07:54:09",
      total: "2",
      status: "Declined By Client",
      sf_invoice_status: "Cancelled",
      reference_number: "CWERX_Test_Record56",
      service_date: "2023-02-23",
      service_date_to: "2023-02-23",
      provider: {
        sfid: "0015K000002gWreQAE",
        account_name: "CENTRAL OSTEOPATHY PTY LTD (Admin Contact)"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5005K000006qrGdQAI",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "2"
    },
    {
      sfid: "a1Z2N000003ec2BUAQ",
      client_approval_status: "Approved",
      created_at: "2023-03-24 02:59:45",
      total: "70",
      status: "Processing",
      sf_invoice_status: "Ready",
      reference_number: "INV001TEST",
      service_date: "2023-02-06",
      service_date_to: "2023-02-12",
      provider: {
        sfid: "0015K000005N2EuQAK",
        account_name: "MPM - Capacity Building"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000FK7xPQAT",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "70"
    },
    {
      sfid: "a1Z2N000003dkk7UAA",
      client_approval_status: "Rejected",
      created_at: "2023-02-06 14:36:41",
      total: "5",
      status: "Declined By Client",
      sf_invoice_status: "Cancelled",
      reference_number: "TESTCABDECLINE1",
      service_date: "2023-02-05",
      service_date_to: "2023-02-05",
      provider: {
        sfid: "0015K000002grDyQAI",
        account_name: "Test Provider"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000FCrUZQA1",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "5"
    },
    {
      sfid: "a1Z2N000003dpljUAA",
      client_approval_status: "Approved",
      created_at: "2023-02-07 09:56:58",
      total: "1",
      status: "Processing",
      sf_invoice_status: "Ready",
      reference_number: "PVTCAB505",
      service_date: "2023-02-01",
      service_date_to: "2023-02-01",
      provider: {
        sfid: "0015K000002grFfQAI",
        account_name: "Test Provider (Mel Test)"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000F9DIsQAN",
        origin: "Email"
      },
      protect_client_data: false,
      amount_due: "1"
    },
    {
      sfid: "a1Z2N000003dkk2UAA",
      client_approval_status: "Approved",
      created_at: "2023-02-06 14:30:04",
      total: "10",
      status: "Awaiting NDIA Approval",
      sf_invoice_status: "NDIA Partially Approved",
      reference_number: "TESTAPPROVALCAB505",
      service_date: "2023-02-01",
      service_date_to: "2023-02-01",
      provider: {
        sfid: "0015K000002grDyQAI",
        account_name: "Test Provider"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000FCrUZQA1",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "10"
    },
    {
      sfid: "a1Z2N000003cIeWUAU",
      client_approval_status: null,
      created_at: "2023-01-12 01:04:33",
      total: "100",
      status: "Processing",
      sf_invoice_status: "Ready",
      reference_number: "TestSms2",
      service_date: "2023-01-11",
      service_date_to: "2023-01-12",
      provider: {
        sfid: "0015K000005a9wdQAA",
        account_name: "Disability Support Link Pty Ltd"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000F9DIsQAN",
        origin: "Email"
      },
      protect_client_data: false,
      amount_due: "100"
    },
    {
      sfid: "a1Z2N000003cHxNUAU",
      client_approval_status: null,
      created_at: "2023-01-11 23:49:18",
      total: "50",
      status: "Processing",
      sf_invoice_status: "Ready",
      reference_number: "TestSms1",
      service_date: "2023-01-11",
      service_date_to: "2023-01-12",
      provider: {
        sfid: "0015K000005a9wdQAA",
        account_name: "Disability Support Link Pty Ltd"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000F9DIsQAN",
        origin: "Email"
      },
      protect_client_data: false,
      amount_due: "50"
    },
    {
      sfid: "a1Z2N000003cIAgUAM",
      client_approval_status: null,
      created_at: "2023-01-12 00:11:52",
      total: "100",
      status: "Processing",
      sf_invoice_status: "Ready",
      reference_number: "TestApprovalP",
      service_date: "2023-01-11",
      service_date_to: "2023-01-12",
      provider: {
        sfid: "0015K000005a9wdQAA",
        account_name: "Disability Support Link Pty Ltd"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000F9DjPQAV",
        origin: "Client Portal"
      },
      protect_client_data: false,
      amount_due: "100"
    }
  ],
  meta: {
    pagination: {
      total: 42,
      count: 15,
      per_page: 15,
      current_page: 1,
      total_pages: 3
    }
  },
  links: {
    self:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___plan=a0k5K00000047BXQAY",
    first:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___plan=a0k5K00000047BXQAY",
    next:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=2&per_page=15&filter___plan=a0k5K00000047BXQAY",
    next_page:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=2&per_page=15&filter___plan=a0k5K00000047BXQAY",
    last:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=3&per_page=15&filter___plan=a0k5K00000047BXQAY",
    last_page: 3,
    current_page: 1,
    per_page: 15,
    total: 42,
    from: 1,
    to: 16,
    pagination: {
      self:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___plan=a0k5K00000047BXQAY",
      first:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___plan=a0k5K00000047BXQAY",
      next:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=2&per_page=15&filter___plan=a0k5K00000047BXQAY",
      next_page:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=2&per_page=15&filter___plan=a0k5K00000047BXQAY",
      last:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=3&per_page=15&filter___plan=a0k5K00000047BXQAY",
      last_page: 3,
      current_page: 1,
      per_page: 15,
      total: 42,
      from: 1,
      to: 16
    }
  }
};

const reimbursements = {
  success: true,
  code: 200,
  message: null,
  data: [
    {
      sfid: "a1Z2N000003eHZzUAM",
      client_approval_status: null,
      created_at: "2023-03-02 04:51:22",
      total: "50",
      status: "Awaiting Payment",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "test",
      service_date: "2023-03-01",
      service_date_to: "2023-03-01",
      provider: {
        sfid: null,
        account_name: "Myself"
      },
      paid_to_type: "reimbursement",
      date_paid: null,
      document_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/BdevQv2EjAY9DEMWD26aETSfxERN2Fca9l6BYvCN.png",
      case: {
        sfid: "5002N00000FHmLSQA1",
        origin: "Client Portal"
      },
      protect_client_data: false,
      amount_due: "50"
    },
    {
      sfid: "a1Z2N000003eJJkUAM",
      client_approval_status: "Approved",
      created_at: "2023-03-09 04:55:28",
      total: "10",
      status: "Awaiting Payment",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "testing1023",
      service_date: "2023-03-01",
      service_date_to: "2023-03-02",
      provider: {
        sfid: null,
        account_name: "Myself"
      },
      paid_to_type: "reimbursement",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5002N00000FIEOcQAP",
        origin: "Auto Generated"
      },
      protect_client_data: false,
      amount_due: "10"
    },
    {
      sfid: "a1Z2N000003cI7mUAE",
      client_approval_status: null,
      created_at: "2023-01-12 00:06:34",
      total: "100",
      status: "Processing",
      sf_invoice_status: "Ready",
      reference_number: "TestApproval",
      service_date: "2023-01-05",
      service_date_to: "2023-01-12",
      provider: {
        sfid: null,
        account_name: "Myself"
      },
      paid_to_type: "reimbursement",
      date_paid: null,
      document_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/9754bbf6099bf79d6851d7689d1b0707/invoices/tPLCH3S8x9DFOjRPASWAN8eAnT5kVu3NdgObfOyb.png",
      case: {
        sfid: "5002N00000F9DjoQAF",
        origin: "Client Portal"
      },
      protect_client_data: false,
      amount_due: "100"
    },
    {
      sfid: "a1Z5K000000ihYaUAI",
      client_approval_status: "Rejected",
      created_at: "2022-12-01 01:43:34",
      total: "1",
      status: "Declined By Client",
      sf_invoice_status: "Cancelled",
      reference_number: "TESTMEL123",
      service_date: "2022-11-30",
      service_date_to: "2022-11-30",
      provider: {
        sfid: null,
        account_name: "Myself"
      },
      paid_to_type: "reimbursement",
      date_paid: null,
      document_link: null,
      case: {
        sfid: "5005K000006lOW1QAM",
        origin: "Email"
      },
      protect_client_data: false,
      amount_due: "1"
    }
  ],
  meta: {
    pagination: {
      total: 4,
      count: 4,
      per_page: 15,
      current_page: 1,
      total_pages: 1
    }
  },
  links: {
    self:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
    first:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
    last:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
    last_page: 1,
    current_page: 1,
    per_page: 15,
    total: 4,
    from: 1,
    to: 4,
    pagination: {
      self:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
      first:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
      last:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
      last_page: 1,
      current_page: 1,
      per_page: 15,
      total: 4,
      from: 1,
      to: 4
    }
  }
};

const uploadedClaims = {
  success: true,
  code: 200,
  message: null,
  data: [
    {
      sfid: "500AD000006fkmbYAA",
      type: "Reimbursements",
      case_number: "123456789",
      date: "2023-07-23 23:34:57",
      status: "Received by MPM",
      invoice_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/L3QHWMrtHxUEfcSCQXu0HGm2rLNDVY0vEotY2nA0.png",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "500AD000006fkhlYAA",
      type: "OCR Invoice",
      case_number: "4573457",
      date: "2023-07-23 23:33:58",
      status: "Received by MPM",
      invoice_link: "",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "500AD000005CrVjYAK",
      type: "Reimbursements",
      case_number: "7967575",
      date: "2023-05-30 14:15:31",
      status: "Received by MPM",
      invoice_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/jCIk6LVWcyz6QE10CBLDr3viRyuNWmR74NA3aeel.pdf",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "500AD0000056oX9YAI",
      type: "OCR Invoice",
      case_number: "964547",
      date: "2023-05-24 19:34:16",
      status: "Received by MPM",
      invoice_link: "",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "500AD0000056hDwYAI",
      type: "Reimbursements",
      case_number: "5544554",
      date: "2023-05-24 15:45:47",
      status: "Received by MPM",
      invoice_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/0rUANYLeHrYOUkoZGuNwtglGaoamRp3HxBywEt9X.jpg",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "5002N00000FLRj6QAH",
      type: "OCR Invoice",
      case_number: "2345678765",
      date: "2023-04-05 14:44:12",
      status: "Received by MPM",
      invoice_link: "",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "5002N00000FLRQNQA5",
      type: "OCR Invoice",
      case_number: "6757645454",
      date: "2023-04-05 13:15:04",
      status: "Received by MPM",
      invoice_link: "",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "5002N00000FLRNiQAP",
      type: "Reimbursements",
      case_number: "11212121",
      date: "2023-04-05 13:03:53",
      status: "Received by MPM",
      invoice_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/GhQMQpCOiLrZd4gMYy9tsfL4xSiO3yTOt4yVXDUw.jpg",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "5002N00000FLRNEQA5",
      type: "Reimbursements",
      case_number: "898980000",
      date: "2023-04-05 13:01:51",
      status: "Received by MPM",
      invoice_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/4Mv7kE9T7gIbK4o95AQ1blY9OspqKO5NAkhm3ZqE.jpg",
      invoice_name: "View Invoice",
      extra_file_name: ""
    },
    {
      sfid: "5002N00000FLREFQA5",
      type: "Reimbursements",
      case_number: "4545464165",
      date: "2023-04-05 12:16:27",
      status: "Received by MPM",
      invoice_link:
        "https://mpm-admin-staging.s3.ap-southeast-2.amazonaws.com/clients/629b38e8d42b2b9e5f5e544d8c04a507/invoices/lxcFUg98HRSZK79JMMMufANeiZzfMZ2Y5fszQr85.jpg",
      invoice_name: "View Invoice",
      extra_file_name: ""
    }
  ],
  meta: {
    pagination: {
      total: 44,
      count: 10,
      per_page: 10,
      current_page: 1,
      total_pages: 5
    }
  },
  links: {
    self:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=1",
    first:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=1",
    next:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=2",
    next_page:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=2",
    last:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=5",
    last_page: 5,
    current_page: 1,
    per_page: 10,
    total: 44,
    from: 1,
    to: 11,
    pagination: {
      self:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=1",
      first:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=1",
      next:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=2",
      next_page:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=2",
      last:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices/uploaded?page=5",
      last_page: 5,
      current_page: 1,
      per_page: 10,
      total: 44,
      from: 1,
      to: 11
    }
  }
};

const invoiceLineItems = {
  success: true,
  code: 200,
  message: null,
  data: [
    {
      sfid: "a1Z2N000003exOXUAY",
      service_date: "2023-03-08",
      service_date_to: "2023-03-08",
      total: "193.99",
      amount_due: "193.99",
      plan: {
        active: false,
        client_sfid: "0012N00000PYlWoQAL",
        end_date: "2023-06-15",
        id: 103675,
        is_active: true,
        name: "15/6/2022 - 15/6/2023",
        sfid: "a0k2N000002UNMwQAO",
        start_date: "2022-06-15",
        status: null
      },
      orig_support_item: {
        sfid: "a0e2N000001uQCcQAM",
        name: "Assessment Recommendation Therapy or Training - Speech Pathologist",
        code: "15_622_0128_1_3"
      },
      support_item: {
        sfid: "a0e2N000001uQCcQAM",
        name: "Assessment Recommendation Therapy or Training - Speech Pathologist",
        code: "15_622_0128_1_3",
        support_category: {
          code: "CB_DAILY_ACTIVITY",
          id: 14,
          name: "Improved Daily Living Skills",
          sfid: "a0c2N000001369HQAQ",
          support_category_name: "Improved Daily Living Skills",
          support_category_number: 15,
          support_type_code: "ZCAP",
          support_type_name: "Capacity Building"
        }
      }
    },
    {
      sfid: "a1Z2N000003exOYUAY",
      service_date: "2023-03-08",
      service_date_to: "2023-03-08",
      total: "25.87",
      amount_due: "193.99",
      plan: {
        active: false,
        client_sfid: "0012N00000PYlWoQAL",
        end_date: "2023-06-15",
        id: 103675,
        is_active: true,
        name: "15/6/2022 - 15/6/2023",
        sfid: "a0k2N000002UNMwQAO",
        start_date: "2022-06-15",
        status: null
      },
      orig_support_item: {
        sfid: "a0e2N000001uQCcQAM",
        name: "Assessment Recommendation Therapy or Training - Speech Pathologist",
        code: "15_622_0128_1_3"
      },
      support_item: {
        sfid: "a0e2N000001uQCcQAM",
        name: "Assistance With Self-Care Activities - Standard -Weekday Daytime - TTP 01_011_0107_1_1_T",
        code: "15_622_0128_1_3",
        support_category: {
          code: "CB_DAILY_ACTIVITY",
          id: 14,
          name: "Improved Daily Living Skills",
          sfid: "a0c2N000001369HQAQ",
          support_category_name: "Improved Daily Living Skills",
          support_category_number: 15,
          support_type_code: "ZCAP",
          support_type_name: "Capacity Building"
        }
      }
    }
  ]
};

const attachment = {
  success: true,
  code: 200,
  message: null,
  data: {
    filename: "Invoice.pdf",
    url:
      "https://myplanmanager--uat.sandbox.file.force.com/sfc/dist/version/download/?oid=00D2N000000Jmcm&ids=0682N000000sdZC&d=%2Fa%2FAD0000003LzB%2Fi5.aaw6VDS6TYAyGlysvFTXVCrHk.0.kpADsnL_Yygc&asPdf=false"
  }
};

const submitClaim = {
  success: true,
  code: 200,
  message: null,
  data: {
    sfid: "5009t000008x2pDAAQ"
  }
};

const claimsForApprovalsCount = {
  data: {
    count: 3
  }
};

const claimsApprovals = {
  success: true,
  code: 200,
  message: null,
  data: [
    {
      sfid: "a1Z2N000003fK9EUAU",
      client_approval_status: "Approved",
      created_at: "2023-04-05 02:20:22",
      total: "20",
      status: "Awaiting Client Approval",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "TEST288",
      service_date: "2023-04-04",
      service_date_to: "2023-04-04",
      provider: {
        sfid: "0015K000002grDyQAI",
        account_name: "Test Provider"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: "/claims/test",
      case: {
        sfid: "5002N00000FLR0nQAH",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "20",
      plan_label: "21 Nov 2023 - 21 Nov 2024",
      plans: [
        {
          active: true,
          client_sfid: "0019p00000O5l26AAB",
          end_date: "2025-03-01",
          id: 144,
          is_active: true,
          name: "1/3/2024 - 1/3/2025",
          sfid: "a0k9p000002tXyHAAU",
          start_date: "2024-03-01",
          status: "Active",
          pace_plan: true,
          first_plan_start_date: null
        },
        {
          active: false,
          client_sfid: "0019p00000O5l26AAB",
          end_date: "2022-12-01",
          id: 144,
          is_active: true,
          name: "1/1/2022 - 12/1/2022",
          sfid: "a0k9p000002tXyHAAU",
          start_date: "2022-01-01",
          status: "Active",
          pace_plan: true,
          first_plan_start_date: null
        }
      ]
    },
    {
      sfid: "a1Z2N000003fK9EUAX",
      client_approval_status: "Declined",
      created_at: "2023-04-05 02:20:22",
      total: "20",
      status: "Awaiting Client Approval",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "TEST288",
      service_date: "2023-04-04",
      service_date_to: "2023-04-04",
      provider: {
        sfid: "0015K000002grDyQAI",
        account_name: "Test Provider"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: "/claims/test",
      case: {
        sfid: "5002N00000FLR0nQAH",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "20",
      plan_label: "21 Nov 2023 - 21 Nov 2024",
      plans: [
        {
          active: true,
          client_sfid: "0019p00000O5l26AAB",
          end_date: "2024-03-01",
          id: 144,
          is_active: true,
          name: "1/3/2024 - 1/3/2025",
          sfid: "a0k9p000002tXyHAAU",
          start_date: "2023-03-01",
          status: "Active",
          pace_plan: true,
          first_plan_start_date: null
        }
      ]
    },
    {
      sfid: "a1Z2N000003fK9EUAG",
      client_approval_status: "Awaiting Client Approval",
      created_at: "2023-04-05 02:20:22",
      total: "20",
      status: "Awaiting Client Approval",
      sf_invoice_status: "Awaiting Payment",
      reference_number: "TEST288",
      service_date: "2023-04-04",
      service_date_to: "2023-04-04",
      provider: {
        sfid: "0015K000002grDyQAI",
        account_name: "Test Provider"
      },
      paid_to_type: "invoice",
      date_paid: null,
      document_link: "/claims/test",
      case: {
        sfid: "5002N00000FLR0nQAH",
        origin: "Provider Portal"
      },
      protect_client_data: false,
      amount_due: "20",
      plan_label: "21 Nov 2023 - 21 Nov 2024",
      plans: [
        {
          active: false,
          client_sfid: "0019p00000O5l26AAB",
          end_date: "2024-03-01",
          id: 144,
          is_active: true,
          name: "1/3/2024 - 1/3/2025",
          sfid: "a0k9p000002tXyHAAU",
          start_date: "2023-03-01",
          status: "Active",
          pace_plan: true,
          first_plan_start_date: null
        }
      ]
    }
  ],
  meta: {
    pagination: {
      total: 2,
      count: 2,
      per_page: 15,
      current_page: 1,
      total_pages: 1
    }
  },
  links: {
    self:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
    first:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
    last:
      "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
    last_page: 1,
    current_page: 1,
    per_page: 15,
    total: 4,
    from: 1,
    to: 4,
    pagination: {
      self:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
      first:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
      last:
        "https://svc-mpm-api-staging.herokuapp.com/api/client-portal/v1/clients/0015K000005dvNkQAI/invoices?page=1&per_page=15&filter___paid_to_type=reimbursement&filter___plan=a0k5K00000047BXQAY",
      last_page: 1,
      current_page: 1,
      per_page: 15,
      total: 1,
      from: 1,
      to: 1
    }
  }
};

const approveClaim = {
  success: true,
  code: 200,
  message: "Succesfully updated invoice"
};

const claimSuggestions = {
  min_version: "7.1.0",
  max_version: "7.1.2",
  success: true,
  code: 200,
  message: null,
  data: {
    suggestions: ["MAETESTCOUNTER2", "TESTCLAIMPAGEAPPRVL2", "TESTINTCLAIMS"]
  }
};
const claim = {
  success: true,
  code: 200,
  data: {
    sfid: "a1Z2N000003fK9EUAX",
    client_approval_status: "Approved",
    created_at: "2024-07-08 03:54:25",
    total: "1",
    status: "Paid",
    sf_invoice_status: "Ready",
    reference_number: "INV0101010101",
    service_date: "2024-07-07",
    service_date_to: "2024-07-07",
    provider: {
      sfid: "0019p00000N0HtFAAV",
      account_name: "ABC Provider"
    },
    paid_to_type: "invoice",
    date_paid: null,
    document_link: null,
    protect_client_data: false,
    amount_due: "1",
    pace_invoice: true,
    plans: [
      {
        active: true,
        client_sfid: "0019p00000O5l26AAB",
        end_date: "2025-03-01",
        id: 144,
        is_active: true,
        name: "1/3/2024 - 1/3/2025",
        sfid: "a0k9p000002tXyHAAU",
        start_date: "2024-03-01",
        status: "Active",
        pace_plan: true,
        first_plan_start_date: null
      }
    ],
    invoice_line_items: [
      {
        sfid: "a1Y9p000000ickjEAA",
        service_date: "2024-07-07",
        service_date_to: "2024-07-07",
        total: "1",
        amount_due: "1",
        plan: {
          active: true,
          client_sfid: "0019p00000O5l26AAB",
          end_date: "2025-03-01",
          id: 144,
          is_active: true,
          name: "1/3/2024 - 1/3/2025",
          sfid: "a0k2N000002UNMwQAO",
          start_date: "2024-03-01",
          status: "Active",
          pace_plan: true,
          first_plan_start_date: null
        },
        orig_support_item: {
          sfid: "a0e9p000000nQluAAE",
          name: "Assistance With Self-Care Activities - Standard - Weekday Night",
          code: "01_002_0107_1_1"
        },
        support_item: {
          sfid: "a0e9p000000nQluAAE",
          name: "Assistance With Self-Care Activities - Standard - Weekday Night",
          code: "01_002_0107_1_1",
          pace_support_category: {
            code: "DAILY_ACTIVITIES",
            id: 1,
            name: "Assistance with Daily Life",
            sfid: "a0c9p000003NU5JAAW",
            support_category_name: "Assistance with Daily Life Non Pace",
            support_category_number: 1,
            support_type_code: "ZCOR",
            support_type_name: "Core",
            pace_support_category_name: "Assistance with Daily Life",
            pace_support_category_code: "Assistance with Daily Life"
          }
        }
      },
      {
        sfid: "a1Y9p000000ickjEGG",
        service_date: "2024-07-07",
        service_date_to: "2024-07-07",
        total: "100",
        amount_due: "100",
        plan: {
          active: true,
          client_sfid: "a0k2N000002UNMwQAO",
          end_date: "2025-03-01",
          id: 144,
          is_active: true,
          name: "1/3/2024 - 1/3/2025",
          sfid: "0019p00000O5l26AAB",
          start_date: "2024-03-01",
          status: "Active",
          pace_plan: true,
          first_plan_start_date: null
        },
        orig_support_item: {
          sfid: "a0e9p000000nQluAAE",
          name: "Assistance With Self-Care Activities - Standard - Weekday Night",
          code: "01_002_0107_1_1"
        },
        support_item: {
          sfid: "a0e9p000000nQluAAE",
          name: "Assistance With Self-Care Activities - Standard - Weekday Night",
          code: "01_002_0107_1_1",
          pace_support_category: {
            code: "DAILY_ACTIVITIES",
            id: 1,
            name: "Assistance with Daily Life",
            sfid: "a0c9p000003NU5JAAW",
            support_category_name: "Assistance with Daily Life",
            support_category_number: 1,
            support_type_code: "ZCOR",
            support_type_name: "Core",
            pace_support_category_name: "Assistance with Daily Life",
            pace_support_category_code: "Assistance with Daily Life"
          }
        }
      },
      {
        sfid: "a1Y9p000000ickjEGG",
        service_date: "2024-07-07",
        service_date_to: "2024-07-07",
        total: "100",
        amount_due: "100",
        plan: {
          active: true,
          client_sfid: "0019p00000O5l26BBB",
          end_date: "2025-03-01",
          id: 144,
          is_active: true,
          name: "1/3/2024 - 1/3/2025",
          sfid: "0019p00000O5l26AABG",
          start_date: "2024-03-01",
          status: "Active",
          pace_plan: true,
          first_plan_start_date: null
        },
        orig_support_item: {
          sfid: "a0e9p000000nQluAAE",
          name: "Assistance With Self-Care Activities - Standard - Weekday Night",
          code: "01_002_0107_1_1"
        },
        support_item: {
          sfid: "a0e9p000000nQluAAE",
          name: "Assistance With Self-Care Activities - Standard - Weekday Night",
          code: "01_002_0107_1_1",
          pace_support_category: {
            code: "DAILY_ACTIVITIES",
            id: 1,
            name: "Assistance with Daily Life",
            sfid: "a0c9p000003NU5JAAW",
            support_category_name: "Assistance with Daily Life",
            support_category_number: 1,
            support_type_code: "ZCOR",
            support_type_name: "Core",
            pace_support_category_name: "Assistance with Daily Life",
            pace_support_category_code: "Assistance with Daily Life"
          }
        }
      }
    ]
  }
};

export {
  claim,
  claims,
  reimbursements,
  uploadedClaims,
  invoiceLineItems,
  attachment,
  submitClaim,
  claimsForApprovalsCount,
  claimsApprovals,
  approveClaim,
  claimSuggestions
};
