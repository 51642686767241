const providers = {
  success: true,
  code: 200,
  message: null,
  data: [
    {
      id: 8961,
      sfid: "0012N00000PZ2EdQAL",
      name: "JBHIFI HOME (HOME WORLD HELENSVALE)",
      company_name: "JB Hi Fi",
      abn: "37093114286"
    },
    {
      id: 18750,
      sfid: "0012N00000PZ47BQAT",
      name: "JBHIFI SOUTHBANK",
      company_name: "JBHIFI SOUTHBANK",
      abn: null
    },
    {
      id: 19559,
      sfid: "0012N00000PZ66IQAT",
      name: "JBHG PTY LTD (Jim's Mowing (Mount Annan))",
      company_name: "JBHG PTY LTD (Jim's Mowing (Mount Annan))",
      abn: "19632320686"
    },
    {
      id: 29838,
      sfid: "0012N00000PZ5HHQA1",
      name: "JBHi-FI Mile End",
      company_name: "JB Hi Fi",
      abn: "37093114286"
    },
    {
      id: 38218,
      sfid: "0012N00000PYqtuQAD",
      name: "JBHIFI Group PTY LTD",
      company_name: "JBHIFI Group PTY LTD",
      abn: null
    },
    {
      id: 42698,
      sfid: "0012N00000PYqanQAD",
      name: "JBHIFI ( CAIRNS CENTRAL)",
      company_name: "JB Hi Fi",
      abn: "37093114286"
    },
    {
      id: 46348,
      sfid: "0012N00000PYnxcQAD",
      name: "JBHIFI  ( Canberra City)",
      company_name: "JB Hi Fi",
      abn: "37093114286"
    },
    {
      id: 46538,
      sfid: "0012N00000PYpOmQAL",
      name: "JBHIFI ( HIGHPOINT )",
      company_name: "JB Hi Fi",
      abn: "37093114286"
    },
    {
      id: 67160,
      sfid: "0012N00000PYrFXQA1",
      name: "JBHIFI (Marion)",
      company_name: "JB Hi Fi",
      abn: "37093114286"
    }
  ],
  meta: {
    pagination: {
      total: 9,
      count: 9,
      per_page: 50,
      current_page: 1,
      total_pages: 1
    }
  },
  links: {
    self: "https://svc-mpm-api-int.herokuapp.com/api/client-portal/v1/providers?page=1&keyword=jbh",
    first: "https://svc-mpm-api-int.herokuapp.com/api/client-portal/v1/providers?page=1&keyword=jbh",
    last: "https://svc-mpm-api-int.herokuapp.com/api/client-portal/v1/providers?page=1&keyword=jbh",
    last_page: 1,
    current_page: 1,
    per_page: 50,
    total: 9,
    from: 1,
    to: 9,
    pagination: {
      self: "https://svc-mpm-api-int.herokuapp.com/api/client-portal/v1/providers?page=1&keyword=jbh",
      first: "https://svc-mpm-api-int.herokuapp.com/api/client-portal/v1/providers?page=1&keyword=jbh",
      last: "https://svc-mpm-api-int.herokuapp.com/api/client-portal/v1/providers?page=1&keyword=jbh",
      last_page: 1,
      current_page: 1,
      per_page: 50,
      total: 9,
      from: 1,
      to: 9
    }
  }
};

const providersSuggestions = {
  success: true,
  code: 200,
  message: null,
  data: {
    suggestions: ["ABC Provider", "My Plan Manager", "XYZ helpers"]
  }
};

const providerClaims = {
  success: true,
  code: 200,
  message: null,
  data: [
    {
      provider_sfid: "0012N00000Q58hRQAR",
      provider_name: "The Speech Spot Illawarra Pty Ltd",
      invoice_count: 2
    },
    {
      provider_sfid: "0012N00000PYmAqQAL",
      provider_name: "Client Care First Pty Ltd",
      invoice_count: 1
    },
    {
      provider_sfid: "0012N00000PYkDkQAL",
      provider_name: "SHINING STARS OCCUPATIONAL THERAPY",
      invoice_count: 3
    },
    {
      provider_sfid: "0015K000002ebHEQAY",
      provider_name: "My Plan Manager",
      invoice_count: 1
    },
    {
      provider_sfid: "0012N00000PYmY5QAL",
      provider_name: "The Disability Trust (Accounts Department)",
      invoice_count: 1
    },
    {
      provider_sfid: "0012N00000Q59Z2QAJ",
      provider_name: "Emma Taylor",
      invoice_count: 1
    }
  ]
};

export { providers, providersSuggestions, providerClaims };
