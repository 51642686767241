<template>
  <div>
    <allocated-budget />
  </div>
</template>

<script>
import UnAllocatedBudget from "@/components/plans/support-items/UnAllocatedBudget";
import AllocatedBudget from "@/components/plans/support-items/AllocatedBudget";

export default {
  name: "PlanSupportCategoryItem",
  components: { AllocatedBudget, UnAllocatedBudget }
};
</script>

<style lang="scss"></style>
